import { useMediaQuery } from "@mantine/hooks";
import { theme } from "../theme/theme";
import { getCookie } from "cookies-next";
import { CURRENCIES } from "@/constants/locale";
import { HANDLING_FEES } from "@/constants";
import { ROUTES } from "@/constants/routes";
import { IJsonLd } from "@/types";

// TODO: Remove condition for prod when dev CDN is ready.
const isProd = process.env.NEXT_PUBLIC_APP_ENV === "production";
export const getAssetUrl = (path: string) =>
  isProd ? `${process.env.NEXT_PUBLIC_CDN_URL}${path}` : path;

export const useDeviceQuery = () => {
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints?.md})`);
  return [isMobile ?? true];
};

export const getBrowserLanguage = () => {
  let lang = navigator.language.toLowerCase();
  if (lang === "en-us") {
    return "en";
  } else if (!["en", "zh-tw", "zh-cn", "ko", "fr", "es", "id"].includes(lang)) {
    return "en";
  }
  return lang;
};

export const formatPrice = (price: number, currency: string, rate: number) => {
  const adjustedPrice = price;
  const calculatePrice = ["USD", "AUD", "EUR"].includes(currency)
    ? adjustedPrice * rate
    : Math.floor(adjustedPrice * rate);

  return calculatePrice;
};

export const getCurrentRates = () => {
  if (typeof window !== "undefined") {
    const rates = sessionStorage.getItem("rates");
    return rates ? JSON.parse(rates) : {};
  }
  return {};
};

export const getCurrency = () => {
  const currency = getCookie("currency") || "JPY";
  return {
    currency: currency,
    symbol: CURRENCIES.find((item) => item.key === currency)?.symbol,
  };
};

export const formatHoursAndMins = (mins: number) => {
  const hours = Math.floor(mins / 60);
  const minutes = mins % 60;
  if (minutes === 0) {
    return `${hours}h`;
  } else if (hours === 0) {
    return `${minutes}m`;
  } else {
    return `${hours}h ${minutes}m`;
  }
};

export const getHandlingFee = (lang: string) => {
  if (["ko", "zh-tw", "zh-cn"].includes(lang)) {
    return HANDLING_FEES.KO;
  } else {
    return HANDLING_FEES.EN;
  }
};

export const defaultRoutes = ROUTES.filter((route) => route.popular === true);

export const getCurrentLocaleUrl = (locale: string) => {
  if (locale === "en") return "";
  return `/${locale}`;
};

export const generateJsonLd = (type: string, lang: string, data: IJsonLd) => {
  return {
    "@context": "https://schema.org",
    "@type": type,
    inLanguage: lang,
    ...data,
  };
};
